// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9785ffc4-ac9d-4591-9ef0-d62f2e4a9cb5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ED3mqEgN0",
    "aws_user_pools_web_client_id": "2ck9984rj6s9jvcid7i4s51j2m",
    "oauth": {},
    "aws_user_files_s3_bucket": "cbd-data-staging",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://wuhxu6gynzgzhchevonaa7mfbm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "cbd-20190612094629-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dw6sm1tk5lngo.cloudfront.net"
};


export default awsmobile;
